



















































import {Component, Vue} from 'vue-property-decorator';
import Footer from '@/components/Footer.vue';
import {EarningTypesEnum} from '@/types/enums/EarningTypesEnum';
import ContactUsModal from '@/components/modals/ContactUsModal.vue';

@Component({
  components: {
    Footer,
    ContactUsModal,
  },
})
export default class PaymentLanding extends Vue {
  isLoaded = false;
  userMessage = '';
  isSuccess = false;
  isFailed = false;
  isTimout = false;
  showContactUs = false;

  showSuccessNotification() {
    this.$notify({
      group: 'foo',
      type: 'success',
      title: 'Success!',
      text: 'Thank you for your purchase',
      duration: 5000,
    });
  }

  mounted() {
    //    // eslint-disable-next-line prettier/prettier, no-debugger
    //    debugger;
    const {payment, message, name: performerUserName, type, id} = this.$route.query;

    if (payment === 'success') {
      this.isLoaded = true;
      this.isSuccess = true;
      this.userMessage = message as string;

      setTimeout(() => {
        switch (type) {
          case EarningTypesEnum.SALE_VIDEO:
            this.$router.push('/movie/' + id, this.showSuccessNotification);
            break;
          case EarningTypesEnum.SALE_PHOTO:
            this.$router.push('/photo/' + id, this.showSuccessNotification);
            break;
          case EarningTypesEnum.PERFORMER_SUB:
          case EarningTypesEnum.TIP_PERFORMER:
            this.$router.push(`/${performerUserName}`, this.showSuccessNotification);
            break;
        }
      }, 2000);
    }

    if (payment === 'failed') {
      this.isLoaded = true;

      if (message === 'TIMEOUT') {
        this.isTimout = true;
        this.userMessage =
          'We are facing some technical difficulty, while processing your payment.' +
          ' \nPlease check your email, contact support for help.';
        return;
      }

      this.isFailed = true;
      this.userMessage =
        'Payment Partner has delined to process your payment, close this window and try with different credit card.';
    }
  }
}
